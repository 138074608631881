<style scoped lang="less">
  .items {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .list {
    flex: 1;
    height: 0;
    overflow: auto;
    padding: 15px;
    box-sizing: border-box;
  }
  .item {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 15px;
    transition: all .3s;
    font-size: 16px;
    color: #000;
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<template>
  <div class="items">
    <fm-input-new style="margin: 0 15px; border-radius: 16px;" clearable v-model="key" placeholder="请输入机构名称">
      <i class="fmico fmico-search" slot="prefix"></i>
    </fm-input-new>
    <div class="list">
      <div class="item" @click="$emit('choose', item)" v-for="item in list" :key="item.key">{{item.label}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      key: null
    }
  },
  computed: {
    list () {
      return this.key ? this.data.filter(v => v.label.indexOf(this.key) > -1) : this.data
    }
  }
}
</script>
