<style scoped lang="less">
  .resume-worker {
    background: #FAFAFA;
    padding: 15px;
    box-sizing: border-box;
  }
  .top {
    display: flex;
  }
  img.avatar {
    background-color: #FFF;
    width: 119px;
    height: 128px;
    object-fit: contain;
  }
  .info {
    flex: 1;
    margin-left: 25px;
  }
  .name {
    font-size: 16px;
    color: #000;
    padding-top: 8px;
  }
  .cells {
    margin-top: 18px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
  .iconfont {
    margin-right: 10px;
  }
  .field {
    color: rgba(0, 0, 0, .7);
  }
  .value {
    color: #000;
  } 
  .bottom {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<template>
  <div class="resume-worker">
    <div class="top">
      <img v-lazy="src" class="avatar" />
      <div class="info">
        <div class="name">{{data.name}}</div>
        <div class="cells">
           <div class="item">
            <i class="iconfont icon-yonghu1 theme-text-color"></i>
            <span class="field">职务：</span>
            <span class="value">{{positionText || '-'}}</span>
          </div>
           <div class="item">
            <i class="iconfont icon-gongshi theme-text-color"></i>
            <span class="field">工龄：</span>
            <span class="value">{{data.maritalStatus || '-'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <span class="field">是否在编：</span>
        <span class="value">{{data.type || '-'}}</span>
      </div>
      <div>
        <span class="field">在岗状态：</span>
        <span class="value">{{data.jobTypeC || '-'}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      loading: {
        file: false
      }
    }
  },
  computed: {
    src () {
      return this.data ? this.$store.getters.fileUrl({id: this.data.headFileId, size: 'small', mode: 'preview'}) : null
    },
    positionText () {
      let newData = []
      this.data.userOrgRoleList.forEach(v => {
        if (v.roleName && !newData.includes(v.roleName)) {
          newData.push(v.roleName)
        }
      })
      return newData.join(',')
    }
  },
  mounted () {
    this.$store.dispatch('loadFileToken')
  }
}
</script>
